import React from 'react';
import { string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import { richText } from '../../util/richText';

import css from './ListingPage.module.css';

const MIN_LENGTH_FOR_LONG_WORDS_IN_DESCRIPTION = 20;

const SectionFreeTextFieldMaybe = props => {
  const { freeText, sectionId } = props;

  return freeText ? (
    <div className={css.sectionDescription}>
      <h2 className={css.sectionTitle}>
        <FormattedMessage id={`${sectionId}Title`} />
      </h2>
      <p className={css.description}>
        {richText(freeText, {
          longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_DESCRIPTION,
          longWordClass: css.longWord,
        })}
      </p>
    </div>
  ) : null;
};

SectionFreeTextFieldMaybe.defaultProps = {
  freeText: null,
};

SectionFreeTextFieldMaybe.propTypes = {
  freeText: string,
  sectionId: string.isRequired,
};

export default SectionFreeTextFieldMaybe;

