import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { string } from 'prop-types';

import css from './ListingPage.module.css';
import ListingField from './ListingField';


const SectionCompanyDetails = props => {
  const {
    companyLocation,
    companyURL
  } = props;

  return companyLocation && companyURL ? (
    <div className={css.sectionDescription}>
      <h2 className={css.sectionTitle}>
        <FormattedMessage id="ListingPage.companyDetails" />
      </h2>
      <div className={css.row}>
        <ListingField id="ListingPage.companyLocation" value={companyLocation} />
        <ListingField id="ListingPage.companyURL" value={companyURL} isValueAsLink/>
      </div>
    </div>
  ) : null;
};

SectionCompanyDetails.propTypes = {
  companyLocation: string,
  companyURL: string
};

export default SectionCompanyDetails;
