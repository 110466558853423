import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { IconCoachValidated } from '../../components';
import config from '../../config';
import { string, object, bool } from 'prop-types';

import css from './ListingPage.module.css';
import { COACH_LISTING } from '../../util/types';


const SectionHeading = props => {
  const {
    richTitle,
    locationToSearch: location,
    isPublished,
    listingType,
    companyName
  } = props;

  const siteTitle = <b>{config.siteTitle}</b>;
  return (
    <div className={css.sectionHeading}>
      <div className={css.heading}>
        {listingType === COACH_LISTING ? (
          <>
            <h1 className={css.title}>{richTitle}</h1>
            {location}
            {isPublished && (
              <div>
                <IconCoachValidated />
                <span className={css.validated}>
                  <FormattedMessage id="ListingPage.validated" values={{siteTitle}}/>
                </span>
              </div>
            )}
          </>
        ) : (
          <>
            <h1 className={css.title}>{companyName}</h1>
            <p className={css.subTitle}>{richTitle}</p>
          </>
        )}
        
      </div>
    </div>
  );
};

SectionHeading.propTypes = {
  richTitle: object.isRequired,
  locationToSearch: string.isRequired,
  isPublished: bool,
  listingType: string,
  companyName: string
};

export default SectionHeading;
