import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { string, object } from 'prop-types';

import css from './ListingPage.module.css';
import ListingField from './ListingField';


const SectionCoachee = props => {
  const {
    firstName,
    familyName,
    roleTitle,
    location,
    reportingLine,
    careerPhase,
    preferredLanguage
  } = props;

  const displayName = `${firstName} ${familyName}`;
  const displayLocation = location ? location.selectedPlace?.address : null;

  return (
    <div className={css.sectionDescription}>
      <h2 className={css.sectionTitle}>
        <FormattedMessage id="ListingPage.coachee" />
      </h2>
      <div className={css.row}>
        <ListingField id="ListingPage.name" value={displayName} />
        <ListingField id="ListingPage.roleTitle" value={roleTitle} />
        <ListingField id="ListingPage.location" value={displayLocation} />
        <ListingField id="ListingPage.reportingLine" value={reportingLine} />
        <ListingField id="ListingPage.careerPhase" value={careerPhase} />
        <ListingField id="ListingPage.preferredLanguage" value={preferredLanguage} />
      </div>
    </div>
  );
};

SectionCoachee.propTypes = {
  firstName: string,
  familyName: string,
  roleTitle: string,
  location: object,
  reportingLine: string,
  careerPhase: string,
  preferredLanguage: string
};

export default SectionCoachee;
