import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { string, bool } from 'prop-types';

import css from './ListingPage.module.css';
import { ExternalLink } from '../../components';
import classNames from 'classnames';


const ListingField = props => {
  const { className, id, value, isValueAsLink } = props;

  return value ? (
    <div className={className}>
      <span className={css.fieldTitle}>
        <FormattedMessage id={id} />
      </span>
      {isValueAsLink ? (
        <ExternalLink href={value} className={classNames(css.fieldValue, css.link)}>
          {value}
        </ExternalLink>
      ) : (
        <p className={css.fieldValue}>{value}</p>
      )}
    </div>
  ) : null;
};

ListingField.defaultProps = {
  className: '',
  isValueAsLink: false
}

ListingField.propTypes = {
  className: string,
  id: string.isRequired,
  value: string.isRequired,
  isValueAsLink: bool
};

export default ListingField;
