import React, { Component } from 'react';
import { array, arrayOf, bool, func, object, shape, string, oneOf } from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import config from '../../config';
import routeConfiguration from '../../routeConfiguration';
import { findOptionsForSelectFilter } from '../../util/search';
import {
  LISTING_STATE_PENDING_APPROVAL,
  LISTING_STATE_PUBLISHED,
  LISTING_STATE_CLOSED,
  propTypes,
  COACH_LISTING,
  ENGAGEMENT_LISTING,
} from '../../util/types';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
  LISTING_PAGE_PARAM_TYPE_DRAFT,
  LISTING_PAGE_PARAM_TYPE_EDIT,
  createSlug,
} from '../../util/urlHelpers';
import { formatMoney } from '../../util/currency';
import {
  createResourceLocatorString,
  findRouteByRouteName,
  pathByRouteName,
} from '../../util/routes';
import {
  ensureListing,
  ensureOwnListing,
  ensureUser,
  userDisplayNameAsString,
} from '../../util/data';
import { timestampToDate, calculateQuantityFromHours } from '../../util/dates';
import { richText } from '../../util/richText';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/UI.duck';
import { initializeCardPaymentData } from '../../ducks/stripe.duck.js';
import {
  Page,
  Modal,
  NamedLink,
  NamedRedirect,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  BookingPanel,
  EngagementAgreement,
  Button,
} from '../../components';
import { EnquiryForm } from '../../forms';
import { TopbarContainer, NotFoundPage } from '../../containers';

import {
  sendEnquiry,
  setInitialValues,
  fetchTimeSlots,
  fetchTransactionLineItems,
  addToCoachList,
  MAX_COACHES_IN_ENGAGEMENT,
  resetCoachListError,
} from './ListingPage.duck';
import SectionAvatar from './SectionAvatar';
import SectionHeading from './SectionHeading';
import SectionDescriptionMaybe from './SectionDescriptionMaybe';
import SectionIntroductionVideoMaybe from './SectionIntroductionVideoMaybe';
import SectionFeaturesMaybe from './SectionFeaturesMaybe';
import SectionFreeTextFieldMaybe from './SectionFreeTextFieldMaybe';
import SectionReviews from './SectionReviews';
import SectionCompanyDetails from './SectionCompanyDetails';
import SectionCoachee from './SectionCoachee';
import {
  COACH_ROLE,
  careerPhaseOptions,
  yesNoOptions,
  BUYER_ROLE,
  coachingFormatOptionsForDropdown,
  COACHEE_ROLE,
} from '../../marketplace-custom-config';
import SectionEngagement from './SectionEngagement';
import SectionSuccessFactors from './SectionSuccessFactors';
import SectionBundleSummary from './SectionBundleSummary';
import { getListingByState } from '../../util/listingHelpers';

import css from './ListingPage.module.css';

const MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE = 16;

const { UUID, Money } = sdkTypes;

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: `(${price.currency})`,
      priceTitle: `Unsupported currency (${price.currency})`,
    };
  }
  return {};
};

const getValueByKey = (options, key) => {
  for (const item of options) {
    if (item.key === key) {
      return item.label;
    }
  }
  return null;
};

const authorizeBuyer = (listing, ownListings) => {
  const { listingType } = listing.attributes.publicData;

  // Buyers only access their engagements
  if (listingType === ENGAGEMENT_LISTING) {
    return ownListings.find(l => l.id.uuid === listing.id.uuid);
  }

  // Buyers only access coach listings if they have at least a published engagement
  if (listingType === COACH_LISTING) {
    const publishedEngagements = getListingByState('published', ownListings);
    return publishedEngagements.length > 0;
  }

  return false;
};

const authorizeCoachee = (listingType, accessibleCoachList) => {
  if (listingType === COACH_LISTING) {
    return accessibleCoachList.includes(listing.id.uuid);
  }

  return false;
};

const authorizeCoach = (listingType, isOwnListing, coachTx) => {
  if (listingType === COACH_LISTING) {
    return isOwnListing;
  }

  if (listingType === ENGAGEMENT_LISTING) {
    return !!coachTx;
  }

  return false;
};

const authorize = ({ role, isOwnListing, ownListings, accessibleCoachList, coachTx, listing }) => {
  const { listingType } = listing.attributes.publicData;

  switch (role) {
    case BUYER_ROLE:
      return authorizeBuyer(listing, ownListings);
    case COACHEE_ROLE:
      return authorizeCoachee(listingType, accessibleCoachList);
    case COACH_ROLE:
      return authorizeCoach(listingType, isOwnListing, coachTx);
    default:
      return false;
  }
};

export class ListingPageComponent extends Component {
  constructor(props) {
    super(props);
    const { enquiryModalOpenForListingId, params } = props;
    this.state = {
      pageClassNames: [],
      imageCarouselOpen: false,
      enquiryModalOpen: enquiryModalOpenForListingId === params.id,
      isOpenAgreementModal: false,
      isOpenListingInstructionModal: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.onContactUser = this.onContactUser.bind(this);
    this.onSubmitEnquiry = this.onSubmitEnquiry.bind(this);
  }

  handleSubmit(values) {
    const {
      history,
      getListing,
      params,
      callSetInitialValues,
      onInitializeCardPaymentData,
    } = this.props;
    const listingId = new UUID(params.id);
    const listing = getListing(listingId);

    const { bookingStartTime, bookingEndTime, ...restOfValues } = values;
    const bookingStart = timestampToDate(bookingStartTime);
    const bookingEnd = timestampToDate(bookingEndTime);

    const bookingData = {
      quantity: calculateQuantityFromHours(bookingStart, bookingEnd),
      ...restOfValues,
    };

    const initialValues = {
      listing,
      bookingData,
      bookingDates: {
        bookingStart,
        bookingEnd,
      },
      confirmPaymentError: null,
    };

    const saveToSessionStorage = !this.props.currentUser;

    const routes = routeConfiguration();
    // Customize checkout page state with current listing and selected bookingDates
    const { setInitialValues } = findRouteByRouteName('CheckoutPage', routes);

    callSetInitialValues(setInitialValues, initialValues, saveToSessionStorage);

    // Clear previous Stripe errors from store if there is any
    onInitializeCardPaymentData();

    // Redirect to CheckoutPage
    history.push(
      createResourceLocatorString(
        'CheckoutPage',
        routes,
        { id: listing.id.uuid, slug: createSlug(listing.attributes.title) },
        {}
      )
    );
  }

  onContactUser() {
    const { currentUser, history, callSetInitialValues, params, location } = this.props;

    if (!currentUser) {
      const state = { from: `${location.pathname}${location.search}${location.hash}` };

      // We need to log in before showing the modal, but first we need to ensure
      // that modal does open when user is redirected back to this listingpage
      callSetInitialValues(setInitialValues, { enquiryModalOpenForListingId: params.id });

      // signup and return back to listingPage.
      history.push(createResourceLocatorString('SignupPage', routeConfiguration(), {}, {}), state);
    } else {
      this.setState({ enquiryModalOpen: true });
    }
  }

  onSubmitEnquiry(values) {
    const { history, params, onSendEnquiry } = this.props;
    const routes = routeConfiguration();
    const listingId = new UUID(params.id);
    const { message } = values;

    onSendEnquiry(listingId, message.trim())
      .then(txId => {
        this.setState({ enquiryModalOpen: false });

        // Redirect to OrderDetailsPage
        history.push(
          createResourceLocatorString('OrderDetailsPage', routes, { id: txId.uuid }, {})
        );
      })
      .catch(() => {
        // Ignore, error handling in duck file
      });
  }

  componentDidMount() {
    if (this.props.params.variant === LISTING_PAGE_PENDING_APPROVAL_VARIANT) {
      this.setState({ isOpenListingInstructionModal: true });
    }
  }

  render() {
    const {
      unitType,
      isAuthenticated,
      currentUser,
      accessibleCoachList,
      fetchAccessibleCoachListInProgress,
      getListing,
      getOwnListing,
      intl,
      onManageDisableScrolling,
      onFetchTimeSlots,
      params: rawParams,
      location,
      history,
      scrollingDisabled,
      showListingError,
      sendEnquiryInProgress,
      sendEnquiryError,
      monthlyTimeSlots,
      filterConfig,
      onFetchTransactionLineItems,
      lineItems,
      fetchLineItemsInProgress,
      fetchLineItemsError,
      projectListings: rawProjectListings,
      onAddToCoachList,
      addToCoachListInProgress,
      addToCoachListError,
      fetchProjectListingsInProgress,
      fetchProjectListingsError,
    } = this.props;

    const listingId = new UUID(rawParams.id);

    const isPendingApprovalVariant = rawParams.variant === LISTING_PAGE_PENDING_APPROVAL_VARIANT;
    const isDraftVariant = rawParams.variant === LISTING_PAGE_DRAFT_VARIANT;
    const currentListing =
      isPendingApprovalVariant || isDraftVariant
        ? ensureOwnListing(getOwnListing(listingId))
        : ensureListing(getListing(listingId));
    const { coachList: coachListOfEngagementListing } = currentListing.attributes.metadata || {};
    const projectListings = rawProjectListings.filter(l => {
      const { coachList = {} } = l.attributes.metadata || {};
      const coachListingIds = Object.keys(coachList);
      return !(
        coachListingIds.length >= MAX_COACHES_IN_ENGAGEMENT &&
        !coachListingIds.includes(currentListing.id?.uuid)
      );
    });
    const listingSlug = rawParams.slug || createSlug(currentListing.attributes.title || '');
    const params = { slug: listingSlug, ...rawParams };

    const editListingLinkType = isDraftVariant
      ? LISTING_PAGE_PARAM_TYPE_DRAFT
      : LISTING_PAGE_PARAM_TYPE_EDIT;

    const isApproved =
      currentListing.id && currentListing.attributes.state !== LISTING_STATE_PENDING_APPROVAL;

    const isPublished =
      currentListing.id && currentListing.attributes.state === LISTING_STATE_PUBLISHED;

    const pendingIsApproved = isPendingApprovalVariant && isApproved;

    // If a /pending-approval URL is shared, the UI requires
    // authentication and attempts to fetch the listing from own
    // listings. This will fail with 403 Forbidden if the author is
    // another user. We use this information to try to fetch the
    // public listing.
    const pendingOtherUsersListing =
      (isPendingApprovalVariant || isDraftVariant) &&
      showListingError &&
      showListingError.status === 403;
    const shouldShowPublicListingPage = pendingIsApproved || pendingOtherUsersListing;

    if (shouldShowPublicListingPage) {
      return <NamedRedirect name="ListingPage" params={params} search={location.search} />;
    }

    const { description = '', price = null, title = '', publicData } = currentListing.attributes;

    const richTitle = (
      <span>
        {richText(title, {
          longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE,
          longWordClass: css.longWord,
        })}
      </span>
    );

    const bookingTitle = (
      <FormattedMessage id="ListingPage.bookingTitle" values={{ title: richTitle }} />
    );

    const topbar = (
      <TopbarContainer
        currentPage="ListingPage"
        showMissingInformationModal={!this.state.isOpenListingInstructionModal}
      />
    );

    if (showListingError && showListingError.status === 404) {
      // 404 listing not found

      return <NotFoundPage />;
    } else if (showListingError || fetchProjectListingsError) {
      // Other error in fetching listing

      const errorTitle = intl.formatMessage({
        id: 'ListingPage.errorLoadingListingTitle',
      });

      return (
        <Page title={errorTitle} scrollingDisabled={scrollingDisabled}>
          <LayoutSingleColumn className={css.pageRoot}>
            <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
            <LayoutWrapperMain>
              <p className={css.errorText}>
                <FormattedMessage id="ListingPage.errorLoadingListingMessage" />
              </p>
            </LayoutWrapperMain>
            <LayoutWrapperFooter>
              <Footer />
            </LayoutWrapperFooter>
          </LayoutSingleColumn>
        </Page>
      );
    } else if (
      !currentListing.id ||
      !currentUser ||
      fetchAccessibleCoachListInProgress ||
      fetchProjectListingsInProgress
    ) {
      // Still loading the listing

      const loadingTitle = intl.formatMessage({
        id: 'ListingPage.loadingListingTitle',
      });

      return (
        <Page title={loadingTitle} scrollingDisabled={scrollingDisabled}>
          <LayoutSingleColumn className={css.pageRoot}>
            <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
            <LayoutWrapperMain>
              <p className={css.loadingText}>
                <FormattedMessage id="ListingPage.loadingListingMessage" />
              </p>
            </LayoutWrapperMain>
            <LayoutWrapperFooter>
              <Footer />
            </LayoutWrapperFooter>
          </LayoutSingleColumn>
        </Page>
      );
    }

    const authorAvailable = currentListing && currentListing.author;
    const userAndListingAuthorAvailable = !!(currentUser && authorAvailable);
    const isOwnListing =
      userAndListingAuthorAvailable && currentListing.author.id.uuid === currentUser.id.uuid;

    const { role, coachListingId } = currentUser?.attributes.profile.publicData || {};

    const { companyName, companyURL, companyLocation, companySummary } =
      authorAvailable?.attributes.profile.publicData || {};

    const {
      listingType,
      firstName,
      familyName,
      roleTitle,
      location: coacheeLocation,
      reportingLine,
      careerPhase,
      preferredLanguage,
      reasonForCoaching,
      coachingFormat,
      goalsOfEngagement,
      isThisCoachingBeingUndertaken,
      shouldBeAwareOfAnyChanges,
      shouldBeAwareOfCompanyCompetencyOrLeadershipFramework,
      explainFurtherContext,
      howManyOneHourSessions,
      hasCompletedAssessment,
      isReportAvailable,
      briefingAndDebriefing,
      maxBudget,
      coacheesDirectManager,
      managersEmail,
      isBoldlyArrange,
      onResetCoachListError,
    } = publicData || {};

    const { siteToContact } = config;

    const formattedMaxBudget = maxBudget
      ? `${maxBudget.currency}${formatMoney(intl, new Money(maxBudget.amount, maxBudget.currency))}`
      : null;

    const coachTx = coachListOfEngagementListing?.[coachListingId];

    const isBuyer = role === BUYER_ROLE;

    const validAccess = authorize({
      role,
      isOwnListing,
      ownListings: rawProjectListings,
      accessibleCoachList,
      coachTx,
      listing: currentListing,
    });
    if (!validAccess) {
      return <NamedRedirect name="LandingPage" />;
    }

    const currentAuthor = authorAvailable ? currentListing.author : null;
    const ensuredAuthor = ensureUser(currentAuthor);

    // When user is banned or deleted the listing is also deleted.
    // Because listing can be never showed with banned or deleted user we don't have to provide
    // banned or deleted display names for the function
    const authorDisplayName = userDisplayNameAsString(ensuredAuthor, '');

    const handleBookingSubmit = values => {
      const isCurrentlyClosed = currentListing.attributes.state === LISTING_STATE_CLOSED;
      if (isOwnListing || isCurrentlyClosed) {
        window.scrollTo(0, 0);
      } else {
        this.handleSubmit(values);
      }
    };

    const listingImages = (listing, variantName) =>
      (listing.images || [])
        .map(image => {
          const variants = image.attributes.variants;
          const variant = variants ? variants[variantName] : null;

          // deprecated
          // for backwards combatility only
          const sizes = image.attributes.sizes;
          const size = sizes ? sizes.find(i => i.name === variantName) : null;

          return variant || size;
        })
        .filter(variant => variant != null);

    const facebookImages = listingImages(currentListing, 'facebook');
    const twitterImages = listingImages(currentListing, 'twitter');
    const schemaImages = JSON.stringify(facebookImages.map(img => img.url));
    const siteTitle = config.siteTitle;
    const schemaTitle = intl.formatMessage({ id: 'ListingPage.schemaTitle' }, { title, siteTitle });

    const hostLink = (
      <NamedLink
        className={css.authorNameLink}
        name="ListingPage"
        params={params}
        to={{ hash: '#host' }}
      >
        {authorDisplayName}
      </NamedLink>
    );

    const coachingFormatOptions = findOptionsForSelectFilter('coachingFormat', filterConfig);
    const languagesOptions = findOptionsForSelectFilter('languages', filterConfig);
    const coachingStylesOptions = findOptionsForSelectFilter('coachingStyles', filterConfig);
    const developmentSituationsOptions = findOptionsForSelectFilter(
      'developmentSituations',
      filterConfig
    );
    const industriesOptions = findOptionsForSelectFilter('industries', filterConfig);
    const coachingAccreditationsOptions = findOptionsForSelectFilter(
      'coachingAccreditations',
      filterConfig
    );
    const assessmentAccreditationsOptions = findOptionsForSelectFilter(
      'assessmentAccreditations',
      filterConfig
    );

    const coachingExperienceValue = publicData.coachingExperience
      ? intl.formatMessage(
          { id: 'ListingPage.coachingExperience' },
          { year: publicData.coachingExperience }
        )
      : null;

    const coachListingContent = () => {
      const {
        introductionVideo,
        firstName,
        coachingAccreditations,
        coachingAccreditationsOther,
        assessmentAccreditations,
        assessmentAccreditationsOther,
        coachingFormat,
        languages,
        languagesOther,
        coachingStyles,
        coachingStylesOther,
        developmentSituations,
        developmentSituationsOther,
        industries,
        industriesOther,
        previousClients,
      } = publicData || {};
      const reviews = ensuredAuthor.attributes.profile.metadata?.reviews || [];
      return (
        <>
          <SectionDescriptionMaybe id="ListingPage.descriptionTitle" description={description} />
          <SectionIntroductionVideoMaybe
            introductionVideo={introductionVideo}
            firstName={firstName}
            isOwnListing={isOwnListing}
          />
          <SectionFeaturesMaybe
            options={coachingAccreditationsOptions}
            selectedOptions={coachingAccreditations}
            sectionId="ListingPage.coachingAccreditations"
            otherValueMaybe={coachingAccreditationsOther}
          />
          <SectionFeaturesMaybe
            options={assessmentAccreditationsOptions}
            selectedOptions={assessmentAccreditations}
            sectionId="ListingPage.assessmentAccreditations"
            otherValueMaybe={assessmentAccreditationsOther}
          />
          <SectionFeaturesMaybe
            options={coachingFormatOptions}
            selectedOptions={coachingFormat}
            sectionId="ListingPage.coachingFormat"
          />
          <SectionFeaturesMaybe
            options={languagesOptions}
            selectedOptions={languages}
            sectionId="ListingPage.languages"
            otherValueMaybe={languagesOther}
          />
          <SectionFeaturesMaybe
            options={coachingStylesOptions}
            selectedOptions={coachingStyles}
            sectionId="ListingPage.coachingStyles"
            otherValueMaybe={coachingStylesOther}
          />
          <SectionFeaturesMaybe
            options={developmentSituationsOptions}
            selectedOptions={developmentSituations}
            sectionId="ListingPage.developmentSituations"
            otherValueMaybe={developmentSituationsOther}
          />
          <SectionFeaturesMaybe
            options={industriesOptions}
            selectedOptions={industries}
            sectionId="ListingPage.industries"
            otherValueMaybe={industriesOther}
          />
          <SectionFreeTextFieldMaybe
            freeText={previousClients}
            sectionId="ListingPage.previousClients"
          />
          <SectionFreeTextFieldMaybe
            freeText={coachingExperienceValue}
            sectionId="ListingPage.coachingExperience"
          />
          <SectionReviews reviews={reviews} />
        </>
      );
    };

    const engagementListingContent = () => {
      return (
        <>
          <SectionDescriptionMaybe
            id="ListingPage.companySummaryTitle"
            description={companySummary}
          />
          <SectionCompanyDetails companyLocation={companyLocation} companyURL={companyURL} />
          <SectionCoachee
            firstName={firstName}
            familyName={familyName}
            roleTitle={roleTitle}
            location={coacheeLocation}
            reportingLine={reportingLine}
            careerPhase={getValueByKey(careerPhaseOptions, careerPhase)}
            preferredLanguage={getValueByKey(languagesOptions, preferredLanguage)}
          />
          <SectionEngagement
            reasonForCoaching={getValueByKey(developmentSituationsOptions, reasonForCoaching)}
            coachingFormat={getValueByKey(coachingFormatOptionsForDropdown, coachingFormat)}
            goalsOfEngagement={goalsOfEngagement}
            isThisCoachingBeingUndertaken={isThisCoachingBeingUndertaken}
            shouldBeAwareOfAnyChanges={shouldBeAwareOfAnyChanges}
            shouldBeAwareOfCompanyCompetencyOrLeadershipFramework={
              shouldBeAwareOfCompanyCompetencyOrLeadershipFramework
            }
            explainFurtherContext={explainFurtherContext}
            howManyOneHourSessions={howManyOneHourSessions}
          />
          <SectionSuccessFactors
            hasCompletedAssessment={getValueByKey(yesNoOptions, hasCompletedAssessment)}
            isReportAvailable={isReportAvailable}
            briefingAndDebriefing={briefingAndDebriefing}
          />
          {isOwnListing && isBuyer && (
            <>
              <SectionBundleSummary
                maxBudget={formattedMaxBudget}
                coacheesDirectManager={coacheesDirectManager}
                managersEmail={managersEmail}
                isBoldlyArrange={isBoldlyArrange}
              />
              <div className={css.wrapperButton}>
                <NamedLink
                  className={css.button}
                  name="EditEngagementPage"
                  params={{
                    id: currentListing.id.uuid,
                    slug: listingSlug,
                    type: editListingLinkType,
                    tab: 'coachee',
                  }}
                >
                  <FormattedMessage id="ListingPage.editListing" />
                </NamedLink>
                <Button
                  className={css.customButton}
                  onClick={() => this.setState({ isOpenAgreementModal: true })}
                >
                  <FormattedMessage id="ListingPage.viewAgreementButton" />
                </Button>
              </div>
            </>
          )}
        </>
      );
    };

    return (
      <Page
        title={schemaTitle}
        scrollingDisabled={scrollingDisabled}
        author={authorDisplayName}
        contentType="website"
        description={description}
        facebookImages={facebookImages}
        twitterImages={twitterImages}
        schema={{
          '@context': 'http://schema.org',
          '@type': 'ItemPage',
          description: description,
          name: schemaTitle,
          image: schemaImages,
        }}
      >
        <LayoutSingleColumn className={css.pageRoot}>
          <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
          <LayoutWrapperMain>
            <div>
              <div className={css.sectionBanner}>
                <div className={css.coachInformation}>
                  <SectionAvatar user={currentAuthor} params={params} />
                  <SectionHeading
                    companyName={companyName}
                    richTitle={richTitle}
                    locationToSearch={publicData?.locationToSearch}
                    isPublished={isPublished}
                    listingType={publicData.listingType}
                  />
                </div>
                <div className={css.backgoundBannerOverlay} />
              </div>
              <div className={css.contentContainer}>
                <div className={css.mainContent}>
                  {listingType === COACH_LISTING
                    ? coachListingContent()
                    : engagementListingContent()}
                </div>
                <BookingPanel
                  className={css.bookingPanel}
                  listing={currentListing}
                  isOwnListing={isOwnListing}
                  unitType={unitType}
                  onSubmit={handleBookingSubmit}
                  title={bookingTitle}
                  authorDisplayName={authorDisplayName}
                  onManageDisableScrolling={onManageDisableScrolling}
                  monthlyTimeSlots={monthlyTimeSlots}
                  onFetchTimeSlots={onFetchTimeSlots}
                  onFetchTransactionLineItems={onFetchTransactionLineItems}
                  lineItems={lineItems}
                  fetchLineItemsInProgress={fetchLineItemsInProgress}
                  fetchLineItemsError={fetchLineItemsError}
                  currentUser={currentUser}
                  projectListings={projectListings}
                  addToCoachList={onAddToCoachList}
                  addToCoachListInProgress={addToCoachListInProgress}
                  addToCoachListError={addToCoachListError}
                  onResetCoachListError={onResetCoachListError}
                />
              </div>
            </div>
            <Modal
              id="ListingPage.enquiry"
              contentClassName={css.enquiryModalContent}
              isOpen={isAuthenticated && this.state.enquiryModalOpen}
              onClose={() => this.setState({ enquiryModalOpen: false })}
              onManageDisableScrolling={onManageDisableScrolling}
            >
              <EnquiryForm
                className={css.enquiryForm}
                submitButtonWrapperClassName={css.enquirySubmitButtonWrapper}
                listingTitle={title}
                authorDisplayName={authorDisplayName}
                sendEnquiryError={sendEnquiryError}
                onSubmit={this.onSubmitEnquiry}
                inProgress={sendEnquiryInProgress}
              />
            </Modal>
            <Modal
              id="ListingPage.agreementModal"
              isOpen={this.state.isOpenAgreementModal}
              onClose={() => this.setState({ isOpenAgreementModal: false })}
              onManageDisableScrolling={onManageDisableScrolling}
              usePortal
              containerClassName={css.agreementModal}
              contentClassName={css.agreementModalContent}
              closeButtonClassName={css.modalCloseButton}
            >
              <EngagementAgreement currentListing={currentListing} currentUser={currentUser} />
            </Modal>
            <Modal
              id="ListingPage.listingInstruction"
              isOpen={this.state.isOpenListingInstructionModal}
              onClose={() => this.setState({ isOpenListingInstructionModal: false })}
              onManageDisableScrolling={onManageDisableScrolling}
              usePortal
              closeButtonMessage={intl.formatMessage({ id: 'ListingPage.closeButton' })}
              containerClassName={css.listingInstructionModal}
            >
              <div className={css.listingInstructionTitle}>
                <FormattedMessage id="ListingPage.listingInstructionTitle" />
              </div>
              <div className={css.listingInstructionBody}>
                <FormattedMessage
                  id="ListingPage.listingInstructionBody"
                  values={{
                    email: <a href={`mailto:${siteToContact}`}>{siteToContact}</a>,
                    lineBreak: <br />,
                  }}
                />
              </div>
            </Modal>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
  }
}

ListingPageComponent.defaultProps = {
  unitType: config.bookingUnitType,
  currentUser: null,
  accessibleCoachList: [],
  enquiryModalOpenForListingId: null,
  showListingError: null,
  monthlyTimeSlots: null,
  sendEnquiryError: null,
  filterConfig: config.custom.filters,
  lineItems: null,
  fetchLineItemsError: null,
  projectListings: [],
  addToCoachListInProgress: false,
  addToCoachListError: null,
  fetchAccessibleCoachListInProgress: false,
  onResetCoachListError: () => {},
  fetchProjectListingsInProgress: false,
  fetchProjectListingsError: null,
};

ListingPageComponent.propTypes = {
  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,

  unitType: propTypes.bookingUnitType,
  // from injectIntl
  intl: intlShape.isRequired,

  params: shape({
    id: string.isRequired,
    slug: string,
    variant: oneOf([LISTING_PAGE_DRAFT_VARIANT, LISTING_PAGE_PENDING_APPROVAL_VARIANT]),
  }).isRequired,

  isAuthenticated: bool.isRequired,
  currentUser: propTypes.currentUser,
  accessibleCoachList: array,
  getListing: func.isRequired,
  getOwnListing: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  scrollingDisabled: bool.isRequired,
  enquiryModalOpenForListingId: string,
  showListingError: propTypes.error,
  callSetInitialValues: func.isRequired,
  monthlyTimeSlots: object,
  // monthlyTimeSlots could be something like:
  // monthlyTimeSlots: {
  //   '2019-11': {
  //     timeSlots: [],
  //     fetchTimeSlotsInProgress: false,
  //     fetchTimeSlotsError: null,
  //   }
  // }
  sendEnquiryInProgress: bool.isRequired,
  sendEnquiryError: propTypes.error,
  onSendEnquiry: func.isRequired,
  onInitializeCardPaymentData: func.isRequired,
  filterConfig: array,
  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,
  projectListings: array,
  addToCoachListInProgress: bool,
  addToCoachListError: propTypes.error,
  fetchAccessibleCoachListInProgress: bool,
  onResetCoachListError: func,
  fetchProjectListingsInProgress: bool,
  fetchProjectListingsError: propTypes.error,
};

const mapStateToProps = state => {
  const { isAuthenticated } = state.Auth;
  const {
    showListingError,
    reviews,
    fetchReviewsError,
    monthlyTimeSlots,
    sendEnquiryInProgress,
    sendEnquiryError,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    enquiryModalOpenForListingId,
    addToCoachListInProgress,
    addToCoachListError,
  } = state.ListingPage;
  const {
    currentUser,
    accessibleCoachList,
    fetchAccessibleCoachListInProgress,
    projectListings,
    fetchProjectListingsInProgress,
    fetchProjectListingsInError,
  } = state.user;

  const getListing = id => {
    const ref = { id, type: 'listing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  const getOwnListing = id => {
    const ref = { id, type: 'ownListing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  return {
    isAuthenticated,
    currentUser,
    accessibleCoachList,
    fetchAccessibleCoachListInProgress,
    getListing,
    getOwnListing,
    scrollingDisabled: isScrollingDisabled(state),
    enquiryModalOpenForListingId,
    showListingError,
    monthlyTimeSlots,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    sendEnquiryInProgress,
    sendEnquiryError,
    projectListings,
    addToCoachListInProgress,
    addToCoachListError,
    fetchProjectListingsInProgress,
    fetchProjectListingsInError,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  callSetInitialValues: (setInitialValues, values, saveToSessionStorage) =>
    dispatch(setInitialValues(values, saveToSessionStorage)),
  onFetchTransactionLineItems: (bookingData, listingId, isOwnListing) =>
    dispatch(fetchTransactionLineItems(bookingData, listingId, isOwnListing)),
  onSendEnquiry: (listingId, message) => dispatch(sendEnquiry(listingId, message)),
  onInitializeCardPaymentData: () => dispatch(initializeCardPaymentData()),
  onFetchTimeSlots: (listingId, start, end, timeZone) =>
    dispatch(fetchTimeSlots(listingId, start, end, timeZone)),
  onAddToCoachList: ({ addCoachList, removeCoachList, coachListingId }) =>
    dispatch(addToCoachList({ addCoachList, removeCoachList, coachListingId })),
  onResetCoachListError: () => dispatch(resetCoachListError()),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const ListingPage = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(ListingPageComponent);

export default ListingPage;
