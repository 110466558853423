import React from 'react';
import { string, array } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import { PropertyGroup } from '../../components';

import css from './ListingPage.module.css';

const SectionFeaturesMaybe = props => {
  const { 
    options,
    sectionId,
    selectedOptions,
    otherValueMaybe = '',
  } = props;

  const selectedConfigOptions = options.filter(o => selectedOptions.find(s => s === o.key));

  return selectedOptions.length > 0 ? (
    <div className={css.sectionFeatures}>
      <h2 className={css.featuresTitle}>
        <FormattedMessage id={`${sectionId}Title`} />
      </h2>
      <PropertyGroup
        id={sectionId}
        options={selectedConfigOptions}
        selectedOptions={selectedOptions}
        otherValueMaybe={otherValueMaybe}
        twoColumns={selectedConfigOptions.length > 4}
      />
    </div>
  ) : null;
};

SectionFeaturesMaybe.defaultProps = {
  otherValueMaybe: '',
  selectedOptions: []
};

SectionFeaturesMaybe.propTypes = {
  options: array.isRequired,
  sectionId: string.isRequired,
  selectedOptions: array,
  otherValueMaybe: string,
};

export default SectionFeaturesMaybe;
