import React, { useState, useEffect, useRef } from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { richText } from '../../util/richText';
import classNames from 'classnames';
import { string } from 'prop-types';
import { Button } from '../../components';

import css from './ListingPage.module.css';

const MIN_LENGTH_FOR_LONG_WORDS_IN_DESCRIPTION = 20;
const MAX_HEIGHT_FOR_DESCRIPTION= 220;

const SectionDescriptionMaybe = props => {
  const ref = useRef();
  const [isShowFullBio, setIsShowFullBio] = useState(false);
  const [isShowReadMoreButton, setIsShowReadMoreButton] = useState(false);
  const { id, description } = props;

  useEffect(() => {
    const { clientHeight = 0 } = ref?.current || {};
    if (clientHeight > MAX_HEIGHT_FOR_DESCRIPTION) {
      setIsShowReadMoreButton(true);
    }
  })

  return description ? (
    <div className={css.sectionDescription}>
      <h2 className={css.descriptionTitle}>
        <FormattedMessage id={id} />
      </h2>
      <pre ref={ref} className={classNames(
        css.description, {
          [css.lessContent]: !isShowFullBio && isShowReadMoreButton
        }
      )}>
        {richText(description, {
          longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_DESCRIPTION,
          longWordClass: css.longWord,
        })}
      </pre>
      {isShowReadMoreButton && (
        <Button 
          className={css.readMoreButton}
          onClick={() => setIsShowFullBio(!isShowFullBio)}>
          <FormattedMessage id={isShowFullBio ? "ListingPage.readLess" : "ListingPage.readMore"} />
          <div 
            className={classNames(
              css.angleDown, {
              [css.angleUp]: isShowFullBio
            })}
          />
        </Button>
      )}
    </div>
  ) : null;
};

SectionDescriptionMaybe.propTypes = {
  id: string.isRequired,
};

export default SectionDescriptionMaybe;
