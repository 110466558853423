import React from 'react';
import { bool, string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import css from './ListingPage.module.css';
import { generateUrlForIntroductionVideo } from '../../util/data';
import classNames from 'classnames';

const SectionIntroductionVideoMaybe = props => {
  const { 
    introductionVideo,
    firstName,
    isOwnListing,
  } = props;

  const validUrl = introductionVideo ? generateUrlForIntroductionVideo(introductionVideo) : undefined;

  const isVimeoVideo = validUrl?.includes("vimeo");

  return introductionVideo ? (
    <div className={css.sectionDescription}>
      <h2 className={css.sectionTitle}>
        <FormattedMessage id="ListingPage.introductionVideoTitle" values={{firstName}} />
      </h2>
      {validUrl ? (
        <div className={classNames({[css.vimeoVideo]: isVimeoVideo})}>
          <iframe
            className={classNames({[css.introductionVideo]: !isVimeoVideo})}
            src={validUrl}
            allowFullScreen="allowfullscreen"
            frameBorder="0"
          />
        </div>
      ): isOwnListing && (
        <div className={css.videoError}>
          <FormattedMessage id="ListingPage.introductionVideoError" />
        </div>
      )}
    </div>
  ) : null;
};


SectionIntroductionVideoMaybe.propTypes = {
  introductionVideo: string.isRequired,
  firstName: string.isRequired,
  isOwnListing: bool.isRequired,
};

export default SectionIntroductionVideoMaybe;
