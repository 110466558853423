import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { string, bool } from 'prop-types';

import css from './ListingPage.module.css';
import ListingField from './ListingField';
import classNames from 'classnames';


const SectionBundleSummary = props => {
  const {
   maxBudget,
   coacheesDirectManager,
   managersEmail,
   isBoldlyArrange,
  } = props;

  return (
    <div className={css.sectionDescription}>
      <h2 className={css.sectionTitle}>
        <FormattedMessage id="ListingPage.bundleSummary" />
      </h2>
      <ListingField id="ListingPage.maxBudget" value={maxBudget} />
      {coacheesDirectManager || managersEmail ? (
        <div className={classNames(css.row, css.questionField)}>
          <ListingField id="ListingPage.coacheesDirectManager" value={coacheesDirectManager} />
          <ListingField id="ListingPage.managersEmail" value={managersEmail} />
        </div>
      ) : null}
      <ListingField
        className={css.questionField}
        id="ListingPage.boldlyArrange"
        value={isBoldlyArrange}
      />
    </div>
  );
};

SectionBundleSummary.propTypes = {
  maxBudget: string,
  coacheesDirectManager: string,
  managersEmail: string,
  isBoldlyArrange: bool,
};

export default SectionBundleSummary;
